// List of languages
const Languages = {
  languages: [
    {
      // IMP: The entire file is based on Malayalam alphabets.
      // To add another language, follow the order of Malayalam.
      // Put a hyphen in the place of missing letter.
      // Diacritics use contents of key: letters as key.
      name: "Malayalam",
      letters: [
        "അ",
        "ആ",
        "ഇ",
        "ഈ",
        "ഉ",
        "ഊ",
        "ഋ",
        "എ",
        "ഏ",
        "ഐ",
        "ഒ",
        "ഓ",
        "ഔ",
        "അം",
        "അഃ",
        "ക",
        "ഖ",
        "ഗ",
        "ഘ",
        "ങ",
        "ച",
        "ഛ",
        "ജ",
        "ഝ",
        "ഞ",
        "ട",
        "ഠ",
        "ഡ",
        "ഢ",
        "ണ",
        "ത",
        "ഥ",
        "ദ",
        "ധ",
        "ന",
        "പ",
        "ഫ",
        "ബ",
        "ഭ",
        "മ",
        "യ",
        "ര",
        "ല",
        "വ",
        "ശ",
        "ഷ",
        "സ",
        "ഹ",
        "ള",
        "ഴ",
        "റ",
      ],
      diacritics: {
        // https://ml.wikipedia.org/wiki/ഫലകം:അക്ഷരമാലാസൂചിക_(സമഗ്രം)
        ക: [
          "ക",
          "കാ",
          "കി",
          "കീ",
          "കു",
          "കൂ",
          "കൃ",
          "കെ",
          "കേ",
          "കൈ",
          "കൊ",
          "കോ",
          "കൌ",
          "കം",
          "കഃ",
        ],
        ഖ: [
          "ഖ",
          "ഖാ",
          "ഖി",
          "ഖീ",
          "ഖു",
          "ഖൂ",
          "ഖൃ",
          "ഖെ",
          "ഖേ",
          "ഖൈ",
          "ഖൊ",
          "ഖോ",
          "ഖൗ",
          "ഖം",
          "ഖഃ",
        ],
        ഗ: [
          "ഗ",
          "ഗാ",
          "ഗി",
          "ഗീ",
          "ഗു",
          "ഗൂ",
          "ഗൃ",
          "ഗെ",
          "ഗേ",
          "ഗൈ",
          "ഗൊ",
          "ഗോ",
          "ഗൗ",
          "ഗം",
          "ഗഃ",
        ],
        ഘ: [
          "ഘ",
          "ഘാ",
          "ഘി",
          "ഘീ",
          "ഘു",
          "ഘൂ",
          "ഘൃ",
          "ഘെ",
          "ഘേ",
          "ഘൈ",
          "ഘൊ",
          "ഘോ",
          "ഘൗ",
          "ഘം",
          "ഘഃ",
        ],
        ങ: [
          "ങ",
          "ങാ",
          "ങി",
          "ങീ",
          "ങു",
          "ങൂ",
          "ങൃ",
          "ങെ",
          "ങേ",
          "ങൈ",
          "ങൊ",
          "ങോ",
          "ങൗ",
          "ങം",
          "ങഃ",
        ],
        ച: [
          "ച",
          "ചാ",
          "ചി",
          "ചീ",
          "ചു",
          "ചൂ",
          "ചൃ",
          "ചെ",
          "ചേ",
          "ചൈ",
          "ചൊ",
          "ചോ",
          "ചൗ",
          "ചം",
          "ചഃ",
        ],
        ഛ: [
          "ഛ",
          "ഛാ",
          "ഛി",
          "ഛീ",
          "ഛു",
          "ഛൂ",
          "ഛൃ",
          "ഛെ",
          "ഛേ",
          "ഛൈ",
          "ഛൊ",
          "ഛോ",
          "ഛൗ",
          "ഛം",
          "ഛഃ",
        ],
        ജ: [
          "ജ",
          "ജാ",
          "ജി",
          "ജീ",
          "ജു",
          "ജൂ",
          "ജൃ",
          "ജെ",
          "ജേ",
          "ജൈ",
          "ജൊ",
          "ജോ",
          "ജൗ",
          "ജം",
          "ജഃ",
        ],
        ഝ: [
          "ഝ",
          "ഝാ",
          "ഝി",
          "ഝീ",
          "ഝു",
          "ഝൂ",
          "ഝൃ",
          "ഝെ",
          "ഝേ",
          "ഝൈ",
          "ഝൊ",
          "ഝോ",
          "ഝൗ",
          "ഝം",
          "ഝഃ",
        ],
        ഞ: [
          "ഞ",
          "ഞാ",
          "ഞി",
          "ഞീ",
          "ഞു",
          "ഞൂ",
          "ഞൃ",
          "ഞെ",
          "ഞേ",
          "ഞൈ",
          "ഞൊ",
          "ഞോ",
          "ഞൗ",
          "ഞം",
          "ഞഃ",
        ],
        ട: [
          "ട",
          "ടാ",
          "ടി",
          "ടീ",
          "ടു",
          "ടൂ",
          "ടൃ",
          "ടെ",
          "ടേ",
          "ടൈ",
          "ടൊ",
          "ടോ",
          "ടൗ",
          "ടം",
          "ടഃ",
        ],
        ഠ: [
          "ഠ",
          "ഠാ",
          "ഠി",
          "ഠീ",
          "ഠു",
          "ഠൂ",
          "ഠൃ",
          "ഠെ",
          "ഠേ",
          "ഠൈ",
          "ഠൊ",
          "ഠോ",
          "ഠൗ",
          "ഠം",
          "ഠഃ",
        ],
        ഡ: [
          "ഡ",
          "ഡാ",
          "ഡി",
          "ഡീ",
          "ഡു",
          "ഡൂ",
          "ഡൃ",
          "ഡെ",
          "ഡേ",
          "ഡൈ",
          "ഡൊ",
          "ഡോ",
          "ഡൗ",
          "ഡം",
          "ഡഃ",
        ],
        ഢ: [
          "ഢ",
          "ഢാ",
          "ഢി",
          "ഢീ",
          "ഢു",
          "ഢൂ",
          "ഢൃ",
          "ഢെ",
          "ഢേ",
          "ഢൈ",
          "ഢൊ",
          "ഢോ",
          "ഢൗ",
          "ഢം",
          "ഢഃ",
        ],
        ണ: [
          "ണ",
          "ണാ",
          "ണി",
          "ണീ",
          "ണു",
          "ണൂ",
          "ണൃ",
          "ണെ",
          "ണേ",
          "ണൈ",
          "ണൊ",
          "ണോ",
          "ണൗ",
          "ണം",
          "ണഃ",
        ],
        ത: [
          "ത",
          "താ",
          "തി",
          "തീ",
          "തു",
          "തൂ",
          "തൃ",
          "തെ",
          "തേ",
          "തൈ",
          "തൊ",
          "തോ",
          "തൗ",
          "തം",
          "തഃ",
        ],
        ഥ: [
          "ഥ",
          "ഥാ",
          "ഥി",
          "ഥീ",
          "ഥു",
          "ഥൂ",
          "ഥൃ",
          "ഥെ",
          "ഥേ",
          "ഥൈ",
          "ഥൊ",
          "ഥോ",
          "ഥൗ",
          "ഥം",
          "ഥഃ",
        ],
        ദ: [
          "ദ",
          "ദാ",
          "ദി",
          "ദീ",
          "ദു",
          "ദൂ",
          "ദൃ",
          "ദെ",
          "ദേ",
          "ദൈ",
          "ദൊ",
          "ദോ",
          "ദൗ",
          "ദം",
          "ദഃ",
        ],
        ധ: [
          "ധ",
          "ധാ",
          "ധി",
          "ധീ",
          "ധു",
          "ധൂ",
          "ധൃ",
          "ധെ",
          "ധേ",
          "ധൈ",
          "ധൊ",
          "ധോ",
          "ധൗ",
          "ധം",
          "ധഃ",
        ],
        ന: [
          "ന",
          "നാ",
          "നി",
          "നീ",
          "നു",
          "നൂ",
          "നൃ",
          "നെ",
          "നേ",
          "നൈ",
          "നൊ",
          "നോ",
          "നൗ",
          "നം",
          "നഃ",
        ],
        പ: [
          "പ",
          "പാ",
          "പി",
          "പീ",
          "പു",
          "പൂ",
          "പൃ",
          "പെ",
          "പേ",
          "പൈ",
          "പൊ",
          "പോ",
          "പൗ",
          "പം",
          "പഃ",
        ],
        ഫ: [
          "ഫ",
          "ഫാ",
          "ഫി",
          "ഫീ",
          "ഫു",
          "ഫൂ",
          "ഫൃ",
          "ഫെ",
          "ഫേ",
          "ഫൈ",
          "ഫൊ",
          "ഫോ",
          "ഫൗ",
          "ഫം",
          "ഫഃ",
        ],
        ബ: [
          "ബ",
          "ബാ",
          "ബി",
          "ബീ",
          "ബു",
          "ബൂ",
          "ബൃ",
          "ബെ",
          "ബേ",
          "ബൈ",
          "ബൊ",
          "ബോ",
          "ബൗ",
          "ബം",
          "ബഃ",
        ],
        ഭ: [
          "ഭ",
          "ഭാ",
          "ഭി",
          "ഭീ",
          "ഭു",
          "ഭൂ",
          "ഭൃ",
          "ഭെ",
          "ഭേ",
          "ഭൈ",
          "ഭൊ",
          "ഭോ",
          "ഭൗ",
          "ഭം",
          "ഭഃ",
        ],
        മ: [
          "മ",
          "മാ",
          "മി",
          "മീ",
          "മു",
          "മൂ",
          "മൃ",
          "മെ",
          "മേ",
          "മൈ",
          "മൊ",
          "മോ",
          "മൗ",
          "മം",
          "മഃ",
        ],
        യ: [
          "യ",
          "യാ",
          "യി",
          "യീ",
          "യു",
          "യൂ",
          "യൃ",
          "യെ",
          "യേ",
          "യൈ",
          "യൊ",
          "യോ",
          "യൗ",
          "യം",
          "യഃ",
        ],
        ര: [
          "ര",
          "രാ",
          "രി",
          "രീ",
          "രു",
          "രൂ",
          "രൃ",
          "രെ",
          "രേ",
          "രൈ",
          "രൊ",
          "രോ",
          "രൗ",
          "രം",
          "രഃ",
        ],
        ല: [
          "ല",
          "ലാ",
          "ലി",
          "ലീ",
          "ലു",
          "ലൂ",
          "ലൃ",
          "ലെ",
          "ലേ",
          "ലൈ",
          "ലൊ",
          "ലോ",
          "ലൗ",
          "ലം",
          "ലഃ",
        ],
        വ: [
          "വ",
          "വാ",
          "വി",
          "വീ",
          "വു",
          "വൂ",
          "വൃ",
          "വെ",
          "വേ",
          "വൈ",
          "വൊ",
          "വോ",
          "വൗ",
          "വം",
          "വഃ",
        ],
        ശ: [
          "ശ",
          "ശാ",
          "ശി",
          "ശീ",
          "ശു",
          "ശൂ",
          "ശൃ",
          "ശെ",
          "ശേ",
          "ശൈ",
          "ശൊ",
          "ശോ",
          "ശൗ",
          "ശം",
          "ശഃ",
        ],
        ഷ: [
          "ഷ",
          "ഷാ",
          "ഷി",
          "ഷീ",
          "ഷു",
          "ഷൂ",
          "ഷൃ",
          "ഷെ",
          "ഷേ",
          "ഷൈ",
          "ഷൊ",
          "ഷോ",
          "ഷൗ",
          "ഷം",
          "ഷഃ",
        ],
        സ: [
          "സ",
          "സാ",
          "സി",
          "സീ",
          "സു",
          "സൂ",
          "സൃ",
          "സെ",
          "സേ",
          "സൈ",
          "സൊ",
          "സോ",
          "സൗ",
          "സം",
          "സഃ",
        ],
        ഹ: [
          "ഹ",
          "ഹാ",
          "ഹി",
          "ഹീ",
          "ഹു",
          "ഹൂ",
          "ഹൃ",
          "ഹെ",
          "ഹേ",
          "ഹൈ",
          "ഹൊ",
          "ഹോ",
          "ഹൗ",
          "ഹം",
          "ഹഃ",
        ],
        ള: [
          "ള",
          "ളാ",
          "ളി",
          "ളീ",
          "ളു",
          "ളൂ",
          "ളൃ",
          "ളെ",
          "ളേ",
          "ളൈ",
          "ളൊ",
          "ളോ",
          "ളൗ",
          "ളം",
          "ളഃ",
        ],
        ഴ: [
          "ഴ",
          "ഴാ",
          "ഴി",
          "ഴീ",
          "ഴു",
          "ഴൂ",
          "ഴൃ",
          "ഴെ",
          "ഴേ",
          "ഴൈ",
          "ഴൊ",
          "ഴോ",
          "ഴൗ",
          "ഴം",
          "ഴഃ",
        ],
        റ: [
          "റ",
          "റാ",
          "റി",
          "റീ",
          "റു",
          "റൂ",
          "റൃ",
          "റെ",
          "റേ",
          "റൈ",
          "റൊ",
          "റോ",
          "റൗ",
          "റം",
          "റഃ",
        ],
      },
    },
    {
      name: "Hindi",
      letters: [
        "अ",
        "आ",
        "इ",
        "ई",
        "उ",
        "ऊ",
        "ऋ",
        "ए",
        "-",
        "ऐ",
        "ओ",
        "-",
        "औ",
        "अं",
        "अः",
        "क",
        "ख",
        "ग",
        "घ",
        "ङ",
        "च",
        "छ",
        "ज",
        "झ",
        "ञ",
        "ट",
        "ठ",
        "ड",
        "ढ",
        "ण",
        "त",
        "थ",
        "द",
        "ध",
        "न",
        "प",
        "फ",
        "ब",
        "भ",
        "म",
        "य",
        "र",
        "ल",
        "व",
        "श",
        "ष",
        "स",
        "ह",
        "-",
        "-",
        "-",
      ],
      diacritics: {
        // https://hi.wikipedia.org/wiki/बारहखड़ी
        क: [
          "क",
          "का",
          "कि",
          "की",
          "कु",
          "कू",
          "-",
          "के",
          "-",
          "कै",
          "को",
          "-",
          "कौ",
          "कं",
          "कः",
        ],
        ख: [
          "ख",
          "खा",
          "खि",
          "खी",
          "खु",
          "खू",
          "-",
          "खे",
          "-",
          "खै",
          "खो",
          "-",
          "खौ",
          "खं",
          "खः",
        ],
        ग: [
          "ग",
          "गा",
          "गि",
          "गी",
          "गु",
          "गू",
          "-",
          "गे",
          "-",
          "गै",
          "गो",
          "-",
          "गौ",
          "गं",
          "गः",
        ],
        घ: [
          "घ",
          "घा",
          "घि",
          "घी",
          "घु",
          "घू",
          "-",
          "घे",
          "-",
          "घै",
          "घो",
          "-",
          "घौ",
          "घं",
          "घः",
        ],
        ङ: [
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
        च: [
          "च",
          "चा",
          "चि",
          "ची",
          "चु",
          "चू",
          "-",
          "चे",
          "-",
          "चै",
          "चो",
          "-",
          "चौ",
          "चं",
          "चः",
        ],
        छ: [
          "छ",
          "छा",
          "छि",
          "छी",
          "छु",
          "छू",
          "-",
          "छे",
          "-",
          "छै",
          "छो",
          "-",
          "छौ",
          "छं",
          "छः",
        ],
        ज: [
          "ज",
          "जा",
          "जि",
          "जी",
          "जु",
          "जू",
          "-",
          "जे",
          "-",
          "जै",
          "जो",
          "-",
          "जौ",
          "जं",
          "जः",
        ],
        झ: [
          "झ",
          "झा",
          "झि",
          "झी",
          "झु",
          "झू",
          "-",
          "झे",
          "-",
          "झै",
          "झो",
          "-",
          "झौ",
          "झं",
          "झः",
        ],
        ञ: [
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
        ट: [
          "ट",
          "टा",
          "टि",
          "टी",
          "टु",
          "टू",
          "-",
          "टे",
          "-",
          "टै",
          "टो",
          "-",
          "टौ",
          "टं",
          "टः",
        ],
        ठ: [
          "ठ",
          "ठा",
          "ठि",
          "ठी",
          "ठु",
          "ठू",
          "-",
          "ठे",
          "-",
          "ठै",
          "ठो",
          "-",
          "ठौ",
          "ठं",
          "ठः",
        ],
        ड: [
          "ड",
          "डा",
          "डि",
          "डी",
          "डु",
          "डू",
          "-",
          "डे",
          "-",
          "डै",
          "डो",
          "-",
          "डौ",
          "डं",
          "डः",
        ],
        ढ: [
          "ढ",
          "ढा",
          "ढि",
          "ढी",
          "ढु",
          "ढू",
          "-",
          "ढे",
          "-",
          "ढै",
          "ढो",
          "-",
          "ढौ",
          "ढं",
          "ढः",
        ],
        ण: [
          "ण",
          "णा",
          "णि",
          "णी",
          "णु",
          "णू",
          "-",
          "णे",
          "-",
          "णै",
          "णो",
          "-",
          "णौ",
          "णं",
          "णः",
        ],
        त: [
          "त",
          "ता",
          "ति",
          "ती",
          "तु",
          "तू",
          "-",
          "ते",
          "-",
          "तै",
          "तो",
          "-",
          "तौ",
          "तं",
          "तः",
        ],
        थ: [
          "थ",
          "था",
          "थि",
          "थी",
          "थु",
          "थू",
          "-",
          "थे",
          "-",
          "थै",
          "थो",
          "-",
          "थौ",
          "थं",
          "थः",
        ],
        द: [
          "द",
          "दा",
          "दि",
          "दी",
          "दु",
          "दू",
          "-",
          "दे",
          "-",
          "दै",
          "दो",
          "-",
          "दौ",
          "दं",
          "दः",
        ],
        ध: [
          "ध",
          "धा",
          "धि",
          "धी",
          "धु",
          "धू",
          "-",
          "धे",
          "-",
          "धै",
          "धो",
          "-",
          "धौ",
          "धं",
          "धः",
        ],
        न: [
          "न",
          "ना",
          "नि",
          "नी",
          "नु",
          "नू",
          "-",
          "ने",
          "-",
          "नै",
          "नो",
          "-",
          "नौ",
          "नं",
          "नः",
        ],
        प: [
          "प",
          "पा",
          "पि",
          "पी",
          "पु",
          "पू",
          "-",
          "पे",
          "-",
          "पै",
          "पो",
          "-",
          "पौ",
          "पं",
          "पः",
        ],
        फ: [
          "फ",
          "फा",
          "फि",
          "फी",
          "फु",
          "फू",
          "-",
          "फे",
          "-",
          "फै",
          "फो",
          "-",
          "फौ",
          "फं",
          "फः",
        ],
        ब: [
          "ब",
          "बा",
          "बि",
          "बी",
          "बु",
          "बू",
          "-",
          "बे",
          "-",
          "बै",
          "बो",
          "-",
          "बौ",
          "बं",
          "बः",
        ],
        भ: [
          "भ",
          "भा",
          "भि",
          "भी",
          "भु",
          "भू",
          "-",
          "भे",
          "-",
          "भै",
          "भो",
          "-",
          "भौ",
          "भं",
          "भः",
        ],
        म: [
          "म",
          "मा",
          "मि",
          "मी",
          "मु",
          "मू",
          "-",
          "मे",
          "-",
          "मै",
          "मो",
          "-",
          "मौ",
          "मं",
          "मः",
        ],
        य: [
          "य",
          "या",
          "यि",
          "यी",
          "यु",
          "यू",
          "-",
          "ये",
          "-",
          "यै",
          "यो",
          "-",
          "यौ",
          "यं",
          "यः",
        ],
        र: [
          "र",
          "रा",
          "रि",
          "री",
          "रु",
          "रू",
          "-",
          "रे",
          "-",
          "रै",
          "रो",
          "-",
          "रौ",
          "रं",
          "रः",
        ],
        ल: [
          "ल",
          "ला",
          "लि",
          "ली",
          "लु",
          "लू",
          "-",
          "ले",
          "-",
          "लै",
          "लो",
          "-",
          "लौ",
          "लं",
          "लः",
        ],
        व: [
          "व",
          "वा",
          "वि",
          "वी",
          "वु",
          "वू",
          "-",
          "वे",
          "-",
          "वै",
          "वो",
          "-",
          "वौ",
          "वं",
          "वः",
        ],
        श: [
          "श",
          "शा",
          "शि",
          "शी",
          "शु",
          "शू",
          "-",
          "शे",
          "-",
          "शै",
          "शो",
          "-",
          "शौ",
          "शं",
          "शः",
        ],
        ष: [
          "ष",
          "षा",
          "षि",
          "षी",
          "षु",
          "षू",
          "-",
          "षे",
          "-",
          "षै",
          "षो",
          "-",
          "षौ",
          "षं",
          "षः",
        ],
        स: [
          "स",
          "सा",
          "सि",
          "सी",
          "सु",
          "सू",
          "-",
          "से",
          "-",
          "सै",
          "सो",
          "-",
          "सौ",
          "सं",
          "सः",
        ],
        ह: [
          "ह",
          "हा",
          "हि",
          "ही",
          "हु",
          "हू",
          "-",
          "हे",
          "-",
          "है",
          "हो",
          "-",
          "हौ",
          "हं",
          "हः",
        ],
      },
    },
    {
      name: "Kannada",
      letters: [
        "ಅ",
        "ಆ",
        "ಇ",
        "ಈ",
        "ಉ",
        "ಊ",
        "ಋ",
        "ಎ",
        "ಏ",
        "ಐ",
        "ಒ",
        "ಓ",
        "ಔ",
        "ಅಂ",
        "ಅಃ",
        "ಕ",
        "ಖ",
        "ಗ",
        "ಘ",
        "ಙ",
        "ಚ",
        "ಛ",
        "ಜ",
        "ಝ",
        "ಞ",
        "ಟ",
        "ಠ",
        "ಡ",
        "ಢ",
        "ಣ",
        "ತ",
        "ಥ",
        "ದ",
        "ಧ",
        "ನ",
        "ಪ",
        "ಫ",
        "ಬ",
        "ಭ",
        "ಮ",
        "ಯ",
        "ರ",
        "ಲ",
        "ವ",
        "ಶ",
        "ಷ",
        "ಸ",
        "ಹ",
        "ಳ",
        "-",
        "-",
      ],
      diacritics: {
        // https://en.wikipedia.org/wiki/Kannada_script#Akshara
        ಕ: [
          "ಕ",
          "ಕಾ",
          "ಕಿ",
          "ಕೀ",
          "ಕು",
          "ಕೂ",
          "ಕೃ",
          "ಕೆ",
          "ಕೇ",
          "ಕೈ",
          "ಕೊ",
          "ಕೋ",
          "ಕೌ",
          "ಕಂ",
          "ಕಃ",
        ],
        ಖ: [
          "ಖ",
          "ಖಾ",
          "ಖಿ",
          "ಖೀ",
          "ಖು",
          "ಖೂ",
          "ಖೃ",
          "ಖೆ",
          "ಖೇ",
          "ಖೈ",
          "ಖೊ",
          "ಖೋ",
          "ಖೌ",
          "ಖಂ",
          "ಖಃ",
        ],
        ಗ: [
          "ಗ",
          "ಗಾ",
          "ಗಿ",
          "ಗೀ",
          "ಗು",
          "ಗೂ",
          "ಗೃ",
          "ಗೆ",
          "ಗೇ",
          "ಗೈ",
          "ಗೊ",
          "ಗೋ",
          "ಗೌ",
          "ಗಂ",
          "ಗಃ",
        ],
        ಘ: [
          "ಘ",
          "ಘಾ",
          "ಘಿ",
          "ಘೀ",
          "ಘು",
          "ಘೂ",
          "ಘೃ",
          "ಘೆ",
          "ಘೇ",
          "ಘೈ",
          "ಘೊ",
          "ಘೋ",
          "ಘೌ",
          "ಘಂ",
          "ಘಃ",
        ],
        ಙ: [
          "ಙ",
          "ಙಾ",
          "ಙಿ",
          "ಙೀ",
          "ಙು",
          "ಙೂ",
          "ಙೃ",
          "ಙೆ",
          "ಙೇ",
          "ಙೈ",
          "ಙೊ",
          "ಙೋ",
          "ಙೌ",
          "ಙಂ",
          "ಙಃ",
        ],
        ಚ: [
          "ಚ",
          "ಚಾ",
          "ಚಿ",
          "ಚೀ",
          "ಚು",
          "ಚೂ",
          "ಚೃ",
          "ಚೆ",
          "ಚೇ",
          "ಚೈ",
          "ಚೊ",
          "ಚೋ",
          "ಚೌ",
          "ಚಂ",
          "ಚಃ",
        ],
        ಛ: [
          "ಛ",
          "ಛಾ",
          "ಛಿ",
          "ಛೀ",
          "ಛು",
          "ಛೂ",
          "ಛೃ",
          "ಛೆ",
          "ಛೇ",
          "ಛೈ",
          "ಛೊ",
          "ಛೋ",
          "ಛೌ",
          "ಛಂ",
          "ಛಃ",
        ],
        ಜ: [
          "ಜ",
          "ಜಾ",
          "ಜಿ",
          "ಜೀ",
          "ಜು",
          "ಜೂ",
          "ಜೃ",
          "ಜೆ",
          "ಜೇ",
          "ಜೈ",
          "ಜೊ",
          "ಜೋ",
          "ಜೌ",
          "ಜಂ",
          "ಜಃ",
        ],
        ಝ: [
          "ಝ",
          "ಝಾ",
          "ಝಿ",
          "ಝೀ",
          "ಝು",
          "ಝೂ",
          "ಝೃ",
          "ಝೆ",
          "ಝೇ",
          "ಝೈ",
          "ಝೊ",
          "ಝೋ",
          "ಝೌ",
          "ಝಂ",
          "ಝಃ",
        ],
        ಞ: [
          "ಞ",
          "ಞಾ",
          "ಞಿ",
          "ಞೀ",
          "ಞು",
          "ಞೂ",
          "ಞೃ",
          "ಞೆ",
          "ಞೇ",
          "ಞೈ",
          "ಞೊ",
          "ಞೋ",
          "ಞೌ",
          "ಞಂ",
          "ಞಃ",
        ],
        ಟ: [
          "ಟ",
          "ಟಾ",
          "ಟಿ",
          "ಟೀ",
          "ಟು",
          "ಟೂ",
          "ಟೃ",
          "ಟೆ",
          "ಟೇ",
          "ಟೈ",
          "ಟೊ",
          "ಟೋ",
          "ಟೌ",
          "ಟಂ",
          "ಟಃ",
        ],
        ಠ: [
          "ಠ",
          "ಠಾ",
          "ಠಿ",
          "ಠೀ",
          "ಠು",
          "ಠೂ",
          "ಠೃ",
          "ಠೆ",
          "ಠೇ",
          "ಠೈ",
          "ಠೊ",
          "ಠೋ",
          "ಠೌ",
          "ಠಂ",
          "ಠಃ",
        ],
        ಡ: [
          "ಡ",
          "ಡಾ",
          "ಡಿ",
          "ಡೀ",
          "ಡು",
          "ಡೂ",
          "ಡೃ",
          "ಡೆ",
          "ಡೇ",
          "ಡೈ",
          "ಡೊ",
          "ಡೋ",
          "ಡೌ",
          "ಡಂ",
          "ಡಃ",
        ],
        ಢ: [
          "ಢ",
          "ಢಾ",
          "ಢಿ",
          "ಢೀ",
          "ಢು",
          "ಢೂ",
          "ಢೃ",
          "ಢೆ",
          "ಢೇ",
          "ಢೈ",
          "ಢೊ",
          "ಢೋ",
          "ಢೌ",
          "ಢಂ",
          "ಢಃ",
        ],
        ಣ: [
          "ಣ",
          "ಣಾ",
          "ಣಿ",
          "ಣೀ",
          "ಣು",
          "ಣೂ",
          "ಣೃ",
          "ಣೆ",
          "ಣೇ",
          "ಣೈ",
          "ಣೊ",
          "ಣೋ",
          "ಣೌ",
          "ಣಂ",
          "ಣಃ",
        ],
        ತ: [
          "ತ",
          "ತಾ",
          "ತಿ",
          "ತೀ",
          "ತು",
          "ತೂ",
          "ತೃ",
          "ತೆ",
          "ತೇ",
          "ತೈ",
          "ತೊ",
          "ತೋ",
          "ತೌ",
          "ತಂ",
          "ತಃ",
        ],
        ಥ: [
          "ಥ",
          "ಥಾ",
          "ಥಿ",
          "ಥೀ",
          "ಥು",
          "ಥೂ",
          "ಥೃ",
          "ಥೆ",
          "ಥೇ",
          "ಥೈ",
          "ಥೊ",
          "ಥೋ",
          "ಥೌ",
          "ಥಂ",
          "ಥಃ",
        ],
        ದ: [
          "ದ",
          "ದಾ",
          "ದಿ",
          "ದೀ",
          "ದು",
          "ದೂ",
          "ದೃ",
          "ದೆ",
          "ದೇ",
          "ದೈ",
          "ದೊ",
          "ದೋ",
          "ದೌ",
          "ದಂ",
          "ದಃ",
        ],
        ಧ: [
          "ಧ",
          "ಧಾ",
          "ಧಿ",
          "ಧೀ",
          "ಧು",
          "ಧೂ",
          "ಧೃ",
          "ಧೆ",
          "ಧೇ",
          "ಧೈ",
          "ಧೊ",
          "ಧೋ",
          "ಧೌ",
          "ಧಂ",
          "ಧಃ",
        ],
        ನ: [
          "ನ",
          "ನಾ",
          "ನಿ",
          "ನೀ",
          "ನು",
          "ನೂ",
          "ನೃ",
          "ನೆ",
          "ನೇ",
          "ನೈ",
          "ನೊ",
          "ನೋ",
          "ನೌ",
          "ನಂ",
          "ನಃ",
        ],
        ಪ: [
          "ಪ",
          "ಪಾ",
          "ಪಿ",
          "ಪೀ",
          "ಪು",
          "ಪೂ",
          "ಪೃ",
          "ಪೆ",
          "ಪೇ",
          "ಪೈ",
          "ಪೊ",
          "ಪೋ",
          "ಪೌ",
          "ಪಂ",
          "ಪಃ",
        ],
        ಫ: [
          "ಫ",
          "ಫಾ",
          "ಫಿ",
          "ಫೀ",
          "ಫು",
          "ಫೂ",
          "ಫೃ",
          "ಫೆ",
          "ಫೇ",
          "ಫೈ",
          "ಫೊ",
          "ಫೋ",
          "ಫೌ",
          "ಫಂ",
          "ಫಃ",
        ],
        ಬ: [
          "ಬ",
          "ಬಾ",
          "ಬಿ",
          "ಬೀ",
          "ಬು",
          "ಬೂ",
          "ಬೃ",
          "ಬೆ",
          "ಬೇ",
          "ಬೈ",
          "ಬೊ",
          "ಬೋ",
          "ಬೌ",
          "ಬಂ",
          "ಬಃ",
        ],
        ಭ: [
          "ಭ",
          "ಭಾ",
          "ಭಿ",
          "ಭೀ",
          "ಭು",
          "ಭೂ",
          "ಭೃ",
          "ಭೆ",
          "ಭೇ",
          "ಭೈ",
          "ಭೊ",
          "ಭೋ",
          "ಭೌ",
          "ಭಂ",
          "ಭಃ",
        ],
        ಮ: [
          "ಮ",
          "ಮಾ",
          "ಮಿ",
          "ಮೀ",
          "ಮು",
          "ಮೂ",
          "ಮೃ",
          "ಮೆ",
          "ಮೇ",
          "ಮೈ",
          "ಮೊ",
          "ಮೋ",
          "ಮೌ",
          "ಮಂ",
          "ಮಃ",
        ],
        ಯ: [
          "ಯ",
          "ಯಾ",
          "ಯಿ",
          "ಯೀ",
          "ಯು",
          "ಯೂ",
          "ಯೃ",
          "ಯೆ",
          "ಯೇ",
          "ಯೈ",
          "ಯೊ",
          "ಯೋ",
          "ಯೌ",
          "ಯಂ",
          "ಯಃ",
        ],
        ರ: [
          "ರ",
          "ರಾ",
          "ರಿ",
          "ರೀ",
          "ರು",
          "ರೂ",
          "ರೃ",
          "ರೆ",
          "ರೇ",
          "ರೈ",
          "ರೊ",
          "ರೋ",
          "ರೌ",
          "ರಂ",
          "ರಃ",
        ],
        ಲ: [
          "ಲ",
          "ಲಾ",
          "ಲಿ",
          "ಲೀ",
          "ಲು",
          "ಲೂ",
          "ಲೃ",
          "ಲೆ",
          "ಲೇ",
          "ಲೈ",
          "ಲೊ",
          "ಲೋ",
          "ಲೌ",
          "ಲಂ",
          "ಲಃ",
        ],
        ವ: [
          "ವ",
          "ವಾ",
          "ವಿ",
          "ವೀ",
          "ವು",
          "ವೂ",
          "ವೃ",
          "ವೆ",
          "ವೇ",
          "ವೈ",
          "ವೊ",
          "ವೋ",
          "ವೌ",
          "ವಂ",
          "ವಃ",
        ],
        ಶ: [
          "ಶ",
          "ಶಾ",
          "ಶಿ",
          "ಶೀ",
          "ಶು",
          "ಶೂ",
          "ಶೃ",
          "ಶೆ",
          "ಶೇ",
          "ಶೈ",
          "ಶೊ",
          "ಶೋ",
          "ಶೌ",
          "ಶಂ",
          "ಶಃ",
        ],
        ಷ: [
          "ಷ",
          "ಷಾ",
          "ಷಿ",
          "ಷೀ",
          "ಷು",
          "ಷೂ",
          "ಷೃ",
          "ಷೆ",
          "ಷೇ",
          "ಷೈ",
          "ಷೊ",
          "ಷೋ",
          "ಷೌ",
          "ಷಂ",
          "ಷಃ",
        ],
        ಸ: [
          "ಸ",
          "ಸಾ",
          "ಸಿ",
          "ಸೀ",
          "ಸು",
          "ಸೂ",
          "ಸೃ",
          "ಸೆ",
          "ಸೇ",
          "ಸೈ",
          "ಸೊ",
          "ಸೋ",
          "ಸೌ",
          "ಸಂ",
          "ಸಃ",
        ],
        ಹ: [
          "ಹ",
          "ಹಾ",
          "ಹಿ",
          "ಹೀ",
          "ಹು",
          "ಹೂ",
          "ಹೃ",
          "ಹೆ",
          "ಹೇ",
          "ಹೈ",
          "ಹೊ",
          "ಹೋ",
          "ಹೌ",
          "ಹಂ",
          "ಹಃ",
        ],
        ಳ: [
          "ಳ",
          "ಳಾ",
          "ಳಿ",
          "ಳೀ",
          "ಳು",
          "ಳೂ",
          "ಳೃ",
          "ಳೆ",
          "ಳೇ",
          "ಳೈ",
          "ಳೊ",
          "ಳೋ",
          "ಳೌ",
          "ಳಂ",
          "ಳಃ",
        ],
      },
    },
    {
      name: "Telugu",
      letters: [
        "అ",
        "ఆ",
        "ఇ",
        "ఈ",
        "ఉ",
        "ఊ",
        "ఋ",
        "ఎ",
        "ఏ",
        "ఐ",
        "ఒ",
        "ఓ",
        "ఔ",
        "అం",
        "అః",
        "క",
        "ఖ",
        "గ",
        "ఘ",
        "ఙ",
        "చ",
        "ఛ",
        "జ",
        "ఝ",
        "ఞ",
        "ట",
        "ఠ",
        "డ",
        "ఢ",
        "ణ",
        "త",
        "థ",
        "ద",
        "ధ",
        "న",
        "ప",
        "ఫ",
        "బ",
        "భ",
        "మ",
        "య",
        "ర",
        "ల",
        "వ",
        "శ",
        "ష",
        "స",
        "హ",
        "ళ",
        "-",
        "-",
      ],
      diacritics: {
        // https://te.wikipedia.org/wiki/తెలుగు_అక్షరాలు
        క: [
          "క",
          "కా",
          "కి",
          "కీ",
          "కు",
          "కూ",
          "కృ",
          "కె",
          "కే",
          "కై",
          "కొ",
          "కో",
          "కౌ",
          "కం",
          "కః",
        ],
        ఖ: [
          "ఖ",
          "ఖా",
          "ఖి",
          "ఖీ",
          "ఖు",
          "ఖూ",
          "ఖృ",
          "ఖె",
          "ఖే",
          "ఖై",
          "ఖొ",
          "ఖో",
          "ఖౌ",
          "ఖం",
          "ఖః",
        ],
        గ: [
          "గ",
          "గా",
          "గి",
          "గీ",
          "గు",
          "గూ",
          "గృ",
          "గె",
          "గే",
          "గై",
          "గొ",
          "గో",
          "గౌ",
          "గం",
          "గః",
        ],
        ఘ: [
          "ఘ",
          "ఘా",
          "ఘి",
          "ఘీ",
          "ఘు",
          "ఘూ",
          "ఘృ",
          "ఘె",
          "ఘే",
          "ఘై",
          "ఘొ",
          "ఘో",
          "ఘౌ",
          "ఘం",
          "ఘః",
        ],
        ఙ: [
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
        చ: [
          "చ",
          "చా",
          "చి",
          "చీ",
          "చు",
          "చూ",
          "చృ",
          "చె",
          "చే",
          "చై",
          "చొ",
          "చో",
          "చౌ",
          "చం",
          "చః",
        ],
        ఛ: [
          "ఛ",
          "ఛా",
          "ఛి",
          "ఛీ",
          "ఛు",
          "ఛూ",
          "ఛృ",
          "ఛె",
          "ఛే",
          "ఛై",
          "ఛొ",
          "ఛో",
          "ఛౌ",
          "ఛం",
          "ఛః",
        ],
        జ: [
          "జ",
          "జా",
          "జి",
          "జీ",
          "జు",
          "జూ",
          "జృ",
          "జె",
          "జే",
          "జై",
          "జొ",
          "జో",
          "జౌ",
          "జం",
          "జః",
        ],
        ఝ: [
          "ఝ",
          "ఝా",
          "ఝి",
          "ఝీ",
          "ఝు",
          "ఝూ",
          "ఝృ",
          "ఝె",
          "ఝే",
          "ఝై",
          "ఝొ",
          "ఝో",
          "ఝౌ",
          "ఝం",
          "ఝః",
        ],
        ఞ: [
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
        ట: [
          "ట",
          "టా",
          "టి",
          "టీ",
          "టు",
          "టూ",
          "టృ",
          "టె",
          "టే",
          "టై",
          "టొ",
          "టో",
          "టౌ",
          "టం",
          "టః",
        ],
        ఠ: [
          "ఠ",
          "ఠా",
          "ఠి",
          "ఠీ",
          "ఠు",
          "ఠూ",
          "ఠృ",
          "ఠె",
          "ఠే",
          "ఠై",
          "ఠొ",
          "ఠో",
          "ఠౌ",
          "ఠం",
          "ఠః",
        ],
        డ: [
          "డ",
          "డా",
          "డి",
          "డీ",
          "డు",
          "డూ",
          "డృ",
          "డె",
          "డే",
          "డై",
          "డొ",
          "డో",
          "డౌ",
          "డం",
          "డః",
        ],
        ఢ: [
          "ఢ",
          "ఢా",
          "ఢి",
          "ఢీ",
          "ఢు",
          "ఢూ",
          "ఢృ",
          "ఢె",
          "ఢే",
          "ఢై",
          "ఢొ",
          "ఢో",
          "ఢౌ",
          "ఢం",
          "ఢః",
        ],
        ణ: [
          "ణ",
          "ణా",
          "ణి",
          "ణీ",
          "ణు",
          "ణూ",
          "ణృ",
          "ణె",
          "ణే",
          "ణై",
          "ణొ",
          "ణో",
          "ణౌ",
          "ణం",
          "ణః",
        ],
        త: [
          "త",
          "తా",
          "తి",
          "తీ",
          "తు",
          "తూ",
          "తృ",
          "తె",
          "తే",
          "తై",
          "తొ",
          "తో",
          "తౌ",
          "తం",
          "తః",
        ],
        థ: [
          "థ",
          "థా",
          "థి",
          "థీ",
          "థు",
          "థూ",
          "థృ",
          "థె",
          "థే",
          "థై",
          "థొ",
          "థో",
          "థౌ",
          "థం",
          "థః",
        ],
        ద: [
          "ద",
          "దా",
          "ది",
          "దీ",
          "దు",
          "దూ",
          "దృ",
          "దె",
          "దే",
          "దై",
          "దొ",
          "దో",
          "దౌ",
          "దం",
          "దః",
        ],
        ధ: [
          "ధ",
          "ధా",
          "ధి",
          "ధీ",
          "ధు",
          "ధూ",
          "ధృ",
          "ధె",
          "ధే",
          "ధై",
          "ధొ",
          "ధో",
          "ధౌ",
          "ధం",
          "ధః",
        ],
        న: [
          "న",
          "నా",
          "ని",
          "నీ",
          "ను",
          "నూ",
          "నృ",
          "నె",
          "నే",
          "నై",
          "నొ",
          "నో",
          "నౌ",
          "నం",
          "నః",
        ],
        ప: [
          "ప",
          "పా",
          "పి",
          "పీ",
          "పు",
          "పూ",
          "పృ",
          "పె",
          "పే",
          "పై",
          "పొ",
          "పో",
          "పౌ",
          "పం",
          "పః",
        ],
        ఫ: [
          "ఫ",
          "ఫా",
          "ఫి",
          "ఫీ",
          "ఫు",
          "ఫూ",
          "ఫృ",
          "ఫె",
          "ఫే",
          "ఫై",
          "ఫొ",
          "ఫో",
          "ఫౌ",
          "ఫం",
          "ఫః",
        ],
        బ: [
          "బ",
          "బా",
          "బి",
          "బీ",
          "బు",
          "బూ",
          "బృ",
          "బె",
          "బే",
          "బై",
          "బొ",
          "బో",
          "బౌ",
          "బం",
          "బః",
        ],
        భ: [
          "భ",
          "భా",
          "భి",
          "భీ",
          "భు",
          "భూ",
          "భృ",
          "భె",
          "భే",
          "భై",
          "భొ",
          "భో",
          "భౌ",
          "భం",
          "భః",
        ],
        మ: [
          "మ",
          "మా",
          "మి",
          "మీ",
          "ము",
          "మూ",
          "మృ",
          "మె",
          "మే",
          "మై",
          "మొ",
          "మో",
          "మౌ",
          "మం",
          "మః",
        ],
        య: [
          "య",
          "యా",
          "యి",
          "యీ",
          "యు",
          "యూ",
          "యృ",
          "యె",
          "యే",
          "యై",
          "యొ",
          "యో",
          "యౌ",
          "యం",
          "యః",
        ],
        ర: [
          "ర",
          "రా",
          "రి",
          "రీ",
          "రు",
          "రూ",
          "రృ",
          "రె",
          "రే",
          "రై",
          "రొ",
          "రో",
          "రౌ",
          "రం",
          "రః",
        ],
        ల: [
          "ల",
          "లా",
          "లి",
          "లీ",
          "లు",
          "లూ",
          "లృ",
          "లె",
          "లే",
          "లై",
          "లొ",
          "లో",
          "లౌ",
          "లం",
          "లః",
        ],
        వ: [
          "వ",
          "వా",
          "వి",
          "వీ",
          "వు",
          "వూ",
          "వృ",
          "వె",
          "వే",
          "వై",
          "వొ",
          "వో",
          "వౌ",
          "వం",
          "వః",
        ],
        శ: [
          "శ",
          "శా",
          "శి",
          "శీ",
          "శు",
          "శూ",
          "శృ",
          "శె",
          "శే",
          "శై",
          "శొ",
          "శో",
          "శౌ",
          "శం",
          "శః",
        ],
        ష: [
          "ష",
          "షా",
          "షి",
          "షీ",
          "షు",
          "షూ",
          "షృ",
          "షె",
          "షే",
          "షై",
          "షొ",
          "షో",
          "షౌ",
          "షం",
          "షః",
        ],
        స: [
          "స",
          "సా",
          "సి",
          "సీ",
          "సు",
          "సూ",
          "సృ",
          "సె",
          "సే",
          "సై",
          "సొ",
          "సో",
          "సౌ",
          "సం",
          "సః",
        ],
        హ: [
          "హ",
          "హా",
          "హి",
          "హీ",
          "హు",
          "హూ",
          "హృ",
          "హౄ",
          "హె",
          "హే",
          "హై",
          "హొ",
          "హో",
          "హౌ",
          "హం",
          "హః",
        ],
        ళ: [
          "ళ",
          "ళా",
          "ళి",
          "ళీ",
          "ళు",
          "ళూ",
          "ళృ",
          "ళె",
          "ళే",
          "ళై",
          "ళొ",
          "ళో",
          "ళౌ",
          "ళం",
          "ళః",
        ],
      },
    },
  ],
};

export default Languages;
