const Defines = {
    Name: "daylamo",
    Prefix: "daylamo:",
    Iwantto: "I want to learn",
    Iknow: "I know",
    Revise: "Revise",
    ReviseWith: "With",
    Answer: "Select your answer"
}

export default Defines;